import { loadScript } from 'lib/loadScript';
import logger from 'lib/logger';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { uuid } from 'uuidv4';
import { UserData } from '../model/User';
import { AxiosResponse } from 'axios';
import { ISSOLoginRequestData, userApi } from '../userApi';
import { finalizeSignIn, setShowMarketing } from 'store/auth/authActions';
import { throttle } from 'lodash';

// Apple types
interface UserI {
    email: string;
    name: NameI;
}
interface NameI {
    firstName: string;
    lastName: string;
}
interface AuthorizationI {
    code: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    id_token: string;
    state: string;
    nonce?: string | undefined;
}
interface SignInResponseI {
    authorization: AuthorizationI;
    user?: UserI;
}
interface SignInSuccessEventI {
    detail: SignInResponseI;
}

export function useAppleSSO(
    onSignInSuccess: (res: UserData) => void,
    onError: (error: AxiosResponse<{ message: string; code: string }>) => void
) {
    const [scriptLoaded, setScriptLoaded] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { settings, merchantId } = useSelector((state: ApplicationState) => state.settings);
    const [appleUserDetails, setAppleUserDetails] = React.useState<SignInResponseI | undefined>(undefined);
    const [brokenUserPopup, setBrokenUserPopup] = React.useState(false);
    const dispatch = useDispatch();
    React.useEffect(() => {
        const onSuccess = throttle((event: SignInSuccessEventI) => {
            setAppleUserDetails(event.detail);
            // 'AppleIDSignInOnSuccess' being called twice, we can't call 'loginAppleSSOUser' without additional data.
        }, 1000);
        const onFailure = (event: any) => {
            // Handle error.
            onError(event.detail);
            console.log('sso error', event.detail);
        };
        // Listen for authorization success.
        document.addEventListener<any>('AppleIDSignInOnSuccess', onSuccess);
        // Listen for authorization failures.
        document.addEventListener('AppleIDSignInOnFailure', onFailure);
        return () => {
            document.removeEventListener<any>('AppleIDSignInOnSuccess', onSuccess);
            document.removeEventListener('AppleIDSignInOnFailure', onFailure);
        };
    }, [dispatch, onError, onSignInSuccess]);
    React.useEffect(() => {
        const attemptSignIn = async () => {
            setLoading(true);
            try {
                if (!appleUserDetails?.authorization?.id_token) {
                    throw new Error('Missing id token.');
                }
                const body: ISSOLoginRequestData = {
                    idToken: appleUserDetails?.authorization?.id_token,
                    firstName: appleUserDetails?.user?.name?.firstName ?? '',
                    lastName: appleUserDetails?.user?.name?.lastName ?? ''
                };
                const { status, ...result } = await userApi.loginAppleSSOUser(body);
                if (status === 201 && (settings?.app?.registrationShowMarketingOptIn ?? true)) {
                    dispatch(setShowMarketing(true));
                }
                finalizeSignIn(result)(dispatch);
                setLoading(true);
                onSignInSuccess(result);
                return result;
            } catch (e: any) {
                // E-PLA-1072 - missing required fields
                if (e.data?.code === 'E-PLA-1072') {
                    setBrokenUserPopup(true);
                }
                logger.error(e.message, e);
                onError(e);
                setLoading(false);
            }
        };
        if (appleUserDetails) {
            attemptSignIn();
        }
    }, [appleUserDetails, dispatch, onError, onSignInSuccess, settings?.app?.registrationShowMarketingOptIn]);
    React.useEffect(() => {
        const initAppleSSO = async () => {
            if (settings?.appleSSO?.webClientId && settings?.appleSSOEnabled) {
                try {
                    if (!scriptLoaded) {
                        // FIXME: make the locale dynamic
                        await loadScript(
                            'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_GB/appleid.auth.js'
                        );
                        setScriptLoaded(true);
                    } else {
                        AppleID.auth.init({
                            clientId: settings?.appleSSO?.webClientId,
                            scope: 'name email',
                            redirectURI: `https://beta-app.pepperhq.com/${merchantId}/appleSSO`,
                            state: 'User authentication request.',
                            nonce: uuid(),
                            usePopup: true
                        });
                    }
                } catch (e: any) {
                    logger.error(e.message, e);
                }
            }
        };
        initAppleSSO();
    }, [merchantId, scriptLoaded, settings?.appleSSO?.webClientId, settings?.appleSSOEnabled]);
    return {
        loading,
        brokenUserPopup
    };
}
