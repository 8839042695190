export enum ELoyaltyProvider {
    Square = 'SQUARE',
    Pepper = 'PEPPER',
    Powercard = 'POWERCARD',
    None = 'NONE'
}

export enum EPerkRedemptionMethod {
    AUTO = 'AUTO',
    CODE_PDF417 = 'CODE_PDF417',
    CODE_QR = 'CODE_QR',
    VOUCHER = 'VOUCHER',
    PSEUDO_CURRENCY = 'PSEUDO_CURRENCY'
}

export enum ELoyaltyTemplate {
    AD_HOC = 'AD_HOC',
    PSEUDO_CURRENCY = 'PSEUDO_CURRENCY',
    STAMP_CARD = 'STAMP_CARD',
    CLUB = 'CLUB',
    LEVEL = 'LEVEL'
}

export enum ELoyaltyScope {
    ITEM = 'ITEM',
    CATEGORY = 'CATEGORY',
    BASKET = 'BASKET',
    ITEM_OR_CATEGORY = 'ITEM_OR_CATEGORY'
}

export enum ELoyaltyDiscountType {
    PERCENTAGE = 'PERCENTAGE',
    VALUE = 'VALUE',
    OVERRIDE = 'OVERRIDE'
}

export enum ELoyaltyDiscountRule {
    CHEAPEST = 'CHEAPEST',
    ALL = 'ALL',
    COSTLIEST = 'COSTLIEST'
}

export enum ELoyaltyDiscountChildItemRule {
    INCLUDE = 'INCLUDE',
    EXCLUDE = 'EXCLUDE'
}

export enum EVerticalAlignment {
    TOP = 'TOP',
    CENTER = 'CENTER',
    BOTTOM = 'BOTTOM'
}
