import { OrderScenario } from 'components/order/model/Order';
import { ETipScheme } from 'components/settings/enums';
import { roundToDecimal } from 'lib/helpers';
import { useAuth } from 'lib/useAuth';
import React from 'react';
import { useBasketTipSettings } from './useBasketTipSettings';
import { ExistingSharedBasketData } from '../helpers';

export function useBasketTips(
    total: number,
    scenario: OrderScenario,
    existingParams: ExistingSharedBasketData
) {
    const { user } = useAuth();
    const { tippingToUse, isTippingEnabled } = useBasketTipSettings(scenario);
    const [userSelectedTipValue, setUserSelectedTipValue] = React.useState(
        existingParams.userSelectedTipValue ?? user?.defaultTip?.amount
    );
    const [useTipAsDefault, setUseTipAsDefault] = React.useState(!!user?.defaultTip);
    const [tipAmount, setTipAmount] = React.useState(existingParams.tipValue ?? 0);

    const roundedTipAmount = React.useMemo(() => roundToDecimal(tipAmount), [tipAmount]);

    const handleSubmitTip = React.useCallback(
        (value: number, actualTipValue?: number) => {
            setTipAmount(value);
            setUserSelectedTipValue(actualTipValue);
        },
        [setUserSelectedTipValue]
    );
    const handleUseTipAsDefaultChange = React.useCallback((value: boolean) => {
        setUseTipAsDefault(value);
    }, []);
    const finalTipAmount = React.useMemo(() => {
        if (!total || !isTippingEnabled || !tippingToUse) {
            return 0;
        }
        return tippingToUse.scheme === ETipScheme.PERCENTAGE
            ? roundToDecimal((tipAmount / total) * 100, 1)
            : roundedTipAmount;
    }, [isTippingEnabled, roundedTipAmount, tipAmount, tippingToUse, total]);

    return {
        tipAmount: roundedTipAmount,
        onSubmitTip: handleSubmitTip,
        onUseTipAsDefaultChange: handleUseTipAsDefaultChange,
        useTipAsDefault,
        userSelectedTipValue,
        finalTipAmount
    };
}
