import { isDefined, isString } from '@pepperhq/location-sdk/dist/lib/typeInference';
import { generateOrderAdjustments } from 'components/order/helpers';
import {
    IAdjustmentLocalResource,
    IOrderCreatePaymentV8,
    IOrderCreateResourceV8,
    IOrderPaymentReadResource,
    OrderErrorCodes,
    OrderPaymentType,
    OrderScenario,
    createOrderCreateItemFromBasketItem
} from 'components/order/model/Order';
import { orderApi } from 'components/order/orderApi';
import { getLocalMerchantId } from 'components/settings/localStore';
import { EPaymentProvider } from 'components/settings/model/Settings';
import { setShouldUpdateGuestSession } from 'components/user/localAuth';
import { UserCard } from 'components/user/model/User';
import { userApi } from 'components/user/userApi';
import logger from 'lib/logger';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import pick from 'lodash/pick';
import { LocationRouteParams, ROUTES } from 'pages/routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GooglePayPaymentDetails } from 'src/integrations/GooglePay';
import { ApplePayPaymentDetails } from 'src/integrations/PaymentProvider/ApplePayButton';
import { IPaymentProvider, paymentProviderFactory } from 'src/integrations/PaymentProvider/PaymentProvider';
import { updateUser } from 'store/auth/authActions';
import { ApplicationState } from 'store/store';
import { setLastRequest } from 'store/request/requestActions';
import { IPaymentOptionValue } from 'components/bill/ui/ViewBill/Checkout/PaymentMethod/PaymentMethodDialog';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { useBasketTipSettings } from './useBasketTipSettings';
import { useSnackbar } from 'notistack';
import { EGAEventName, isHIBSV4, isTortilla, useGAHelpers } from 'lib/useGAHelpers';
import { useParams } from 'react-router-dom';

const getChallengeUrl = () =>
    `${process.env.ORDER_SERVICE_URL}/worldpay/3ds/redirectVerification?redirectUrl=${window.location.origin}${ROUTES.WORLDPAY}`;

export function usePlaceOrder(
    totalToPay: number,
    tip: number,
    useTipAsDefault: boolean,
    selectedPaymentOption: IPaymentOptionValue,
    scrollToPhoneNumberInput: () => void,
    validatePhoneNumber: () => boolean,
    onAddNewCard: () => void,
    phoneNumber?: string,
    reward?: IAdjustmentLocalResource,
    giftCardPayment?: IOrderPaymentReadResource
) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isGuest, user } = useAuth();
    const { push } = useLocalHistory();
    const [scenarioFromRoute] = useRouteScenario();
    const { currentLocation } = useSelector((state: ApplicationState) => state.locations);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const basket = useSelector((state: ApplicationState) => state.basket);
    const [isPlacingOrder, setIsPlacingOrder] = React.useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);
    const [provider, setProvider] = React.useState<IPaymentProvider>();
    const { enqueueSnackbar } = useSnackbar();
    const { merchantId, locationId } = useParams<LocationRouteParams>();
    const { logUserEvent, getBasketEventBody } = useGAHelpers();

    const scenario = React.useMemo(() => scenarioFromRoute ?? OrderScenario.PREORDER, [scenarioFromRoute]);
    const { tippingToUse } = useBasketTipSettings(scenario);

    React.useEffect(() => {
        if (settings && !provider) {
            paymentProviderFactory.create(settings).then(paymentProvider => {
                setProvider(paymentProvider);
            });
        }
        return () => {
            if (provider && provider.reset) {
                provider.reset();
            }
        };
    }, [provider, provider?.reset, settings]);

    const phoneCaptureEnabled = React.useMemo(
        () => !!settings?.capturePhoneNumberOnOrder && settings?.capturePhoneNumberOnOrder[scenario],
        [scenario, settings?.capturePhoneNumberOnOrder]
    );
    const notes = React.useMemo(() => {
        if (OrderScenario.PREORDER === scenario) {
            return currentLocation?.ordering?.extendedFields.PREORDER?.note ?? [];
        }
        if (OrderScenario.ORDER_TO_TABLE === scenario) {
            return currentLocation?.ordering?.extendedFields.ORDER_TO_TABLE?.note ?? [];
        }
        return [];
    }, [
        scenario,
        currentLocation?.ordering?.extendedFields?.PREORDER?.note,
        currentLocation?.ordering?.extendedFields?.ORDER_TO_TABLE?.note
    ]);
    const handleToggleConfirmationDialog = React.useCallback(
        () => setIsConfirmationOpen(!isConfirmationOpen),
        [isConfirmationOpen]
    );

    const updateUserPhoneNumber = React.useCallback(async () => {
        const phoneNumberValid = validatePhoneNumber();
        if (!phoneNumberValid) {
            scrollToPhoneNumberInput();
            throw new Error('The phone number is invalid');
        }
        if (user && phoneNumber) {
            try {
                const userResult = await userApi.updateUser(user.id, {
                    phoneNumberLastUsedToOrder: phoneNumber
                });
                updateUser(userResult);
            } catch (e) {
                logger.error(e);
            }
        }
    }, [phoneNumber, scrollToPhoneNumberInput, user, validatePhoneNumber]);

    const placeOrder = React.useCallback(
        (
                paymentType?: OrderPaymentType,
                _paymentCard?: UserCard | null,
                options?: {
                    auth?: { nonce?: string; threeDSecureData?: { verificationToken: string } };
                    wallet?:
                        | GooglePayPaymentDetails
                        | ApplePayPaymentDetails
                        | { worldpay: GooglePayPaymentDetails | ApplePayPaymentDetails };
                }
            ) =>
            () => {
                // paymentCard is the card selected when paying by card.
                // currently more than one card per account is not supported in backend,
                // but if this changes, we can use this parameter to easily charge the
                // correct card that the user has selected.
                setIsPlacingOrder(true); // Prevent multiple settings of orders via different methods

                if ((isTortilla(merchantId) || isHIBSV4(merchantId)) && locationId) {
                    logUserEvent(
                        EGAEventName.PlaceOrderDetailed,
                        getBasketEventBody(basket.items, locationId)
                    );
                }

                const updateDefaultTips = async () => {
                    if (!!user && !!tippingToUse && useTipAsDefault) {
                        try {
                            const userResult = await userApi.updateUser(user._id, {
                                defaultTip: { amount: tip, scheme: tippingToUse.scheme }
                            });
                            dispatch(updateUser(userResult));
                        } catch (error) {
                            logger.warn(error);
                        }
                    }
                };

                async function createOrder(paymentNonce: string | null) {
                    // Update default tips if checked
                    await updateDefaultTips();
                    // Place order
                    try {
                        const merchantId = getLocalMerchantId();
                        if (!merchantId) {
                            throw new Error('Missing merchantId');
                        }
                        const payments: IOrderCreatePaymentV8[] = [];
                        if (isDefined(paymentType) && paymentType !== OrderPaymentType.GIFT_CARD) {
                            const payment: IOrderCreatePaymentV8 = {
                                type: paymentType,
                                amount: totalToPay ?? 0
                            };
                            if (options?.auth) {
                                payment.auth = !!paymentNonce
                                    ? { ...options.auth, nonce: paymentNonce }
                                    : options.auth;
                            }
                            if (options?.wallet) {
                                payment.wallet = options.wallet;
                            }

                            if (
                                !!provider &&
                                !payment?.auth &&
                                payment.type === OrderPaymentType.CARD_ON_FILE
                            ) {
                                const deviceDataResult = await provider.collectDeviceData();

                                if (!!deviceDataResult) {
                                    const { sessionId, transactionReference } = deviceDataResult;
                                    if (isString(sessionId)) {
                                        payment.auth = {
                                            threeDSecureData: {
                                                deviceData: {
                                                    collectionReference: sessionId,
                                                    userAgentHeader: window.navigator.userAgent
                                                },
                                                challenge: {
                                                    returnUrl: getChallengeUrl()
                                                },
                                                transactionReference
                                            }
                                        };
                                    }
                                }
                            }

                            if (!(giftCardPayment && payment.amount === 0)) {
                                payments.push(payment);
                            }
                        }

                        if (giftCardPayment) {
                            payments.push(
                                pick(giftCardPayment, [
                                    'auth',
                                    'provider',
                                    'type',
                                    'amount'
                                ]) as IOrderPaymentReadResource
                            );
                        }

                        const orderBody: IOrderCreateResourceV8 = {
                            adjustments: generateOrderAdjustments(tip, reward),
                            locationId: basket.locationId,
                            scenario,
                            isOpen: false,
                            items: basket.items.map(createOrderCreateItemFromBasketItem),
                            payments
                        };

                        if (scenario !== OrderScenario.PREORDER) {
                            orderBody.deliveryLocation = basket.deliveryLocation;
                        }

                        if (
                            !!basket.timeSlot &&
                            scenario !== OrderScenario.ORDER_TO_TABLE &&
                            scenario !== OrderScenario.TABLE
                        ) {
                            orderBody.timeSlot = { start: basket.timeSlot.start, end: basket.timeSlot.end };
                        }

                        if (Array.isArray(notes)) {
                            const orderNotes: string[] = [];
                            notes.forEach(item => {
                                const currentNoteValue = basket.notes[item.title];
                                if (!!currentNoteValue) {
                                    orderNotes.push(`${item.title}: ${currentNoteValue}`);
                                }
                            });
                            if (orderNotes.length > 0) {
                                orderBody.note = orderNotes.join('; ');
                            }
                        }
                        const operation = await orderApi.createOrder(orderBody);
                        // We need it for the case the user clicks back button right after submitting an order
                        if (isDefined(operation.id)) {
                            if (isGuest) {
                                setShouldUpdateGuestSession(merchantId);
                            }

                            push(
                                ROUTES.ORDER.OPERATION,
                                {
                                    operationId: operation.id.toString()
                                },
                                '',
                                { orderBodyToRecreate: orderBody }
                            );
                        }
                    } catch (err: any) {
                        if (err?.data?.code === OrderErrorCodes.TIMESLOT_NOT_AVAILABLE) {
                            push(ROUTES.ORDER.BASKET, {}, 'error=timeslot');
                        }
                        logger.error(err);
                        if (!err) {
                            handleToggleConfirmationDialog();
                        } else if (err?.data?.code !== OrderErrorCodes.TIMESLOT_NOT_AVAILABLE) {
                            enqueueSnackbar(t('CHECKOUT_ERROR_PLACING_ORDER'), {
                                variant: 'error'
                            });
                        }
                        setIsPlacingOrder(false);
                    }
                }
                createOrder(null);
            },
        [
            merchantId,
            locationId,
            logUserEvent,
            getBasketEventBody,
            basket.items,
            basket.locationId,
            basket.timeSlot,
            basket.deliveryLocation,
            basket.notes,
            user,
            tippingToUse,
            useTipAsDefault,
            tip,
            dispatch,
            giftCardPayment,
            reward,
            scenario,
            notes,
            totalToPay,
            provider,
            isGuest,
            push,
            handleToggleConfirmationDialog,
            enqueueSnackbar,
            t
        ]
    );
    const handleDigitalPayment = React.useCallback(
        (cardNonce: string, walletType: OrderPaymentType) => {
            if (phoneCaptureEnabled) {
                updateUserPhoneNumber();
            }
            placeOrder(walletType, null, { auth: { nonce: cardNonce } })();
        },
        [placeOrder, phoneCaptureEnabled, updateUserPhoneNumber]
    );
    const handleDigitalPaymentWithArgs = React.useCallback(
        (cardNonce: string, walletType: OrderPaymentType) => {
            const isPhoneValid = validatePhoneNumber();
            if (isPhoneValid) {
                handleDigitalPayment(cardNonce, walletType);
            }
        },
        [handleDigitalPayment, validatePhoneNumber]
    );
    const handleJudoDigitalPayment = React.useCallback(
        (data: GooglePayPaymentDetails | ApplePayPaymentDetails, walletType: OrderPaymentType) => {
            if (phoneCaptureEnabled) {
                updateUserPhoneNumber();
            }
            placeOrder(
                walletType,
                null,
                settings?.paymentProvider === EPaymentProvider.WORLDPAY
                    ? {
                          wallet: { worldpay: data }
                      }
                    : { wallet: data }
            )();
        },
        [placeOrder, phoneCaptureEnabled, settings?.paymentProvider, updateUserPhoneNumber]
    );
    const handleJudoDigitalPaymentWithArgs = React.useCallback(
        (data: GooglePayPaymentDetails | ApplePayPaymentDetails, walletType: OrderPaymentType) => {
            const isPhoneValid = validatePhoneNumber();
            if (isPhoneValid) {
                handleJudoDigitalPayment(data, walletType);
            }
        },
        [handleJudoDigitalPayment, validatePhoneNumber]
    );
    const handleSubmit = React.useCallback(async () => {
        if (phoneCaptureEnabled) {
            try {
                await updateUserPhoneNumber();
            } catch (e) {
                logger.error(e);
                return;
            }
        }
        if (selectedPaymentOption.card) {
            if (
                provider &&
                settings &&
                settings?.paymentProvider === EPaymentProvider.SQUARE &&
                user &&
                user.card
            ) {
                userApi.getCards(user.id).then(res => {
                    if (res.cards.length > 0 && res.cards[0].cardId) {
                        const userSelectedCard = res.cards.find(
                            c =>
                                selectedPaymentOption.card &&
                                selectedPaymentOption.card._id &&
                                c._id === selectedPaymentOption.card._id
                        );
                        if (userSelectedCard?.cardId) {
                            provider.threeDSecureAuthenticate(
                                {
                                    square: {
                                        cardId: userSelectedCard.cardId,
                                        intent: 'CHARGE',
                                        currency: settings.region.currencyCode,
                                        contact: {
                                            givenName: user?.firstName,
                                            familyName: user?.lastName
                                        },
                                        onVerificationEnd: (err, result) => {
                                            if (err || !result) {
                                                enqueueSnackbar(err?.message ?? 'Something went wrong', {
                                                    variant: 'error'
                                                });
                                            } else {
                                                const createOrderFunction = placeOrder(
                                                    OrderPaymentType.CARD_ON_FILE,
                                                    userSelectedCard,
                                                    {
                                                        auth: {
                                                            threeDSecureData: {
                                                                verificationToken: result.token
                                                            }
                                                        }
                                                    }
                                                );
                                                setLastRequest(createOrderFunction)(dispatch);
                                                createOrderFunction();
                                            }
                                        }
                                    }
                                },
                                {
                                    payments: [
                                        {
                                            amount: totalToPay ?? 0,
                                            type: OrderPaymentType.CARD_ON_FILE
                                        }
                                    ]
                                }
                            );
                        }
                    }
                });
            } else {
                const createOrderFunction = placeOrder(
                    OrderPaymentType.CARD_ON_FILE,
                    selectedPaymentOption.card
                );
                setLastRequest(createOrderFunction)(dispatch);
                createOrderFunction();
            }
        } else if (totalToPay === 0 && giftCardPayment) {
            const createOrderFunction = placeOrder();
            setLastRequest(createOrderFunction)(dispatch);
            createOrderFunction();
        } else {
            onAddNewCard();
        }
    }, [
        phoneCaptureEnabled,
        selectedPaymentOption.card,
        totalToPay,
        giftCardPayment,
        updateUserPhoneNumber,
        provider,
        settings,
        user,
        enqueueSnackbar,
        placeOrder,
        dispatch,
        onAddNewCard
    ]);
    const handleSubmitWithArgs = React.useCallback(() => {
        const isPhoneValid = validatePhoneNumber();
        if (isPhoneValid) {
            handleSubmit();
        }
    }, [handleSubmit, validatePhoneNumber]);
    return {
        submitOrder: handleSubmitWithArgs,
        submitDigitalOrder: handleDigitalPaymentWithArgs,
        submiteJudoDigitalOrder: handleJudoDigitalPaymentWithArgs,
        isPlacingOrder,
        isConfirmationOpen,
        handleToggleConfirmationDialog
    };
}
