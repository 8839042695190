import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { ELoyaltyProvider } from 'components/awards/enums';
import { useAuth } from 'lib/useAuth';
import { CredentialProvider } from 'components/user/model/User';
import { Skeleton } from '@material-ui/lab';
import { UserQrCodeBlock } from './UserQrCodeBlock';

const useStyles = makeStyles({
    barcode: {
        height: 'auto',
        width: '100%'
    }
});

const DEFAULT_BARCODE_PREFIX = 'p377';

export const UserQrCode: React.FC = () => {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const [barcodeSvg, setBarcodeSvg] = React.useState<string>();
    const [barcodeLoading, setBarcodeLoading] = React.useState(false);
    const classes = useStyles();
    const { user } = useAuth();
    const prefix = React.useMemo(
        () => settings?.app?.userBarcodePrefix ?? DEFAULT_BARCODE_PREFIX,
        [settings?.app?.userBarcodePrefix]
    );
    const getPowercardString = React.useCallback(() => {
        const primaryCredential = user?.credentials?.find(item => item.isPrimary);
        if (
            primaryCredential?.provider === CredentialProvider.APPLE ||
            primaryCredential?.provider === CredentialProvider.GOOGLE
        ) {
            return user?.credentials?.find(item => item.provider === CredentialProvider.EMAIL)?.id;
        }
        if (primaryCredential?.provider === CredentialProvider.EMAIL) {
            return primaryCredential.id;
        }
        if (primaryCredential?.provider === CredentialProvider.MOBILE) {
            return primaryCredential.id.replace('+', '').trimStart();
        }
        return undefined;
    }, [user?.credentials]);
    const qrCodeValue = React.useMemo(() => {
        switch (settings?.loyaltyProvider) {
            case ELoyaltyProvider.Powercard:
                return getPowercardString();
            case ELoyaltyProvider.Square:
                return user?._id;
            case ELoyaltyProvider.Pepper:
            case ELoyaltyProvider.None:
            default:
                return `${prefix}${user?._id}`;
        }
    }, [getPowercardString, prefix, settings?.loyaltyProvider, user?._id]);

    React.useEffect(() => {
        if (qrCodeValue && (!settings?.app?.userBarcodeFormat || settings?.app?.userBarcodeFormat !== 'QR')) {
            setBarcodeLoading(true);
            import('bwip-js').then(bwip => {
                const bwipSvg = bwip.toSVG({
                    bcid: 'pdf417',
                    text: qrCodeValue,
                    height: 12,
                    includetext: true,
                    textxalign: 'center',
                    textcolor: '000000'
                });
                const svg = new Blob([bwipSvg], { type: 'image/svg+xml' });
                const url = URL.createObjectURL(svg);
                setBarcodeSvg(url);
                setBarcodeLoading(false);
            });
        }
    }, [qrCodeValue, settings?.app?.userBarcodeFormat]);
    if (!qrCodeValue) {
        return null;
    }
    if (settings?.app?.userBarcodeFormat === 'QR') {
        return (
            <UserQrCodeBlock qrCodeValue={qrCodeValue} loyaltyProvider={settings?.loyaltyProvider}>
                <QRCodeSVG value={qrCodeValue} />
            </UserQrCodeBlock>
        );
    }
    if (barcodeLoading) {
        return (
            <UserQrCodeBlock qrCodeValue={qrCodeValue} loyaltyProvider={settings?.loyaltyProvider}>
                <Skeleton height={85} width={300} variant="rect" />
            </UserQrCodeBlock>
        );
    }
    return (
        <UserQrCodeBlock qrCodeValue={qrCodeValue} loyaltyProvider={settings?.loyaltyProvider}>
            <img className={classes.barcode} src={barcodeSvg} />
        </UserQrCodeBlock>
    );
};
