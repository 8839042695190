import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import { usePhoneNumberState } from 'components/basket/usePhoneNumberState';
import { ViewBillHeader } from 'components/bill/ui/ViewBill/ViewBillHeader';
import { IAdjustmentLocalResource } from 'components/order/model/Order';
import { EmptyState } from 'lib/EmptyState';
import { Throbber } from 'lib/Throbber';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { getMenu } from 'store/menu/menuActions';
import { ApplicationState } from 'store/store';
import { LocationRouteParams, ROUTES } from './routes';
import { useOrderRewards } from 'components/order/hooks/useOrderRewards';
import { resetBasket, updateBasketItemQuantity, updateTimePadding } from 'store/basket/basketActions';
import { EOrderNonceKey, orderApi } from 'components/order/orderApi';
import { BasketTimeslotPicker } from 'components/basket/BasketTimeslotPicker';
import { InnerPageLayout } from 'lib/InnerPageLayout';
import { InnerPageLayoutContent } from 'lib/InnerPageLayout/InnerPageLayoutContent';
import { ViewBillHeaderInfo } from 'components/bill/ui/ViewBill/ViewBillHeaderInfo';
import { ViewBillContentSection } from 'components/bill/ui/ViewBill/ViewBillContentSection';
import { ConfirmDialog } from 'lib/ConfirmDialog';
import { ViewBasketRewardList } from 'components/basket/Reward/ViewBasketRewardList';
import { ViewBillCheckoutPhoneNumber } from 'components/basket/ViewBillCheckoutPhoneNumber';
import { ViewBillCheckoutNoteList } from 'components/bill/ui/ViewBill/Checkout/ViewBillCheckoutNoteList';
import { ViewBillCheckoutTip } from 'components/bill/ui/ViewBill/Checkout/Tip';
import { ViewBillPaymentMethod } from 'components/bill/ui/ViewBill/Checkout/PaymentMethod';
import { CardPayment } from 'components/basket/CardPayment';
import { GuestCheckout } from 'components/bill/ui/ViewBill/GuestCheckout';
import { BottomDialog } from 'lib/BottomDialog';
import { QuantitySelector } from 'lib/QuantitySelector';
import { ViewBasketPayButton } from 'components/basket/payments/ViewBasketPayButton';
import { ViewBasketPaymentInfo } from 'components/basket/ViewBasketPaymentInfo';
import { usePlaceOrder } from 'components/basket/hooks/usePlaceOrder';
import { BasketItems } from 'components/basket/BasketItems';
import { useCalculateBasket } from 'components/basket/hooks/useCalculateBasket';
import { useGiftCards } from 'components/order/hooks/useGiftCards';
import { BasketGiftCard } from 'components/basket/BasketGiftCard';
import { useOrderGiftcardCalculator } from 'components/order/hooks/usePayGiftcardCalculator';
import { useBasketPaymentMethods } from 'components/basket/hooks/useBasketPaymentMethods';
import { useBasketTips } from 'components/basket/hooks/useBasketTips';
import { useBasketTipSettings } from 'components/basket/hooks/useBasketTipSettings';
import { ExistingSharedBasketData, getBasketSession, setBasketSession } from 'components/basket/helpers';
import { clearSessionStorage } from 'lib/helpers';
import { useTransactionLimitValidation } from 'components/basket/hooks/useTransactionLimitValidation';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContainer: {
            display: 'flex',
            height: '100%',
            paddingBottom: theme.spacing(7),
            overflowY: 'hidden',
            alignItems: 'center'
        },
        basketError: {
            fontSize: theme.spacing(12),
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(-6)
        },
        emptyBasket: {
            fontSize: `${theme.spacing(14)}px`,
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(-6) // Fix appbar screen middle offset
        }
    })
);

export const ViewBasketPage: React.FC = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { getState } = useStore<ApplicationState>();
    const { locationId, merchantId } = useParams<LocationRouteParams>();
    const { search } = useLocation();
    const { push } = useLocalHistory();
    const basket = useSelector((state: ApplicationState) => state.basket);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { lastRequest } = useSelector((state: ApplicationState) => state.request);
    const [existingParams, setExistingParams] = React.useState<ExistingSharedBasketData>(
        () => getBasketSession(merchantId, locationId, basket?.checkId, basket?.deliveryLocation) ?? {}
    );
    const {
        value: phoneNumber,
        error: phoneNumberError,
        onChange: handlePhoneNumberChange,
        validatePhoneNumber,
        phoneCaptureEnabled
    } = usePhoneNumberState();
    const [menuIsReady, setMenuIsReady] = React.useState(false);
    const { calculatedBasket, loading, scenario } = useCalculateBasket(menuIsReady);
    const { card, isCardLoading } = useAuth();

    const { availableRewards, selectedReward, handleSetReward, handleClearRewards, rewardsEnabled } =
        useOrderRewards(
            calculatedBasket?.availableAdjustments,
            calculatedBasket?.total?.total ?? 0,
            existingParams
        );
    const totalAfterReward = React.useMemo(() => {
        if (!calculatedBasket) {
            return 0;
        }
        const afterRewardIsApplied = calculatedBasket.total.total + (selectedReward?.totalValue ?? 0);
        if (afterRewardIsApplied < 0) {
            return 0;
        }
        return afterRewardIsApplied;
    }, [calculatedBasket, selectedReward?.totalValue]);
    const { tipAmount, userSelectedTipValue, onSubmitTip, onUseTipAsDefaultChange, useTipAsDefault } =
        useBasketTips(totalAfterReward, scenario, existingParams);
    const {
        selectedPaymentOption,
        onCardAdded,
        addNewCardOpen,
        onAddNewCardClose,
        onAddNewCardOpen,
        onCardChange
    } = useBasketPaymentMethods();

    const [openClearBasketDialog, setOpenClearBasketDialog] = React.useState(false);

    const [quantitySelectProductIndex, setQuantitySelectProductIndex] = React.useState(-1);
    const [quantity, setQuantity] = React.useState(0);

    const userCards = React.useMemo(() => (card ? [card] : []), [card]);
    const phoneNumberWrapperRef = React.useRef<HTMLDivElement>(null);
    const scrollToPhoneNumberInput = React.useCallback(() => {
        if (phoneNumberWrapperRef.current) {
            phoneNumberWrapperRef.current.scrollIntoView({ behavior: 'smooth' });
            const [firstInputInsideWrapper] = phoneNumberWrapperRef.current.getElementsByTagName('input');
            if (firstInputInsideWrapper) {
                firstInputInsideWrapper.focus();
            }
        }
    }, []);

    const {
        giftCard,
        giftCardBalance,
        giftCardError,
        giftCardLoading,
        submitGiftCard,
        resetGiftCard,
        resetGiftCardErrors
    } = useGiftCards(existingParams.giftCard);

    React.useEffect(() => {
        const loadMenu = async () => {
            await getMenu(
                locationId || '',
                basket.timeSlot?.start,
                scenario,
                t('DIALOG_TIMESLOT_CHANGED_MESSAGE'),
                basket?.deliveryLocation
            )(dispatch);
            setMenuIsReady(true);
        };
        loadMenu();
    }, [dispatch, locationId, scenario, basket.timeSlot?.start, t, basket?.deliveryLocation]);

    const handleOnBack = React.useCallback(() => push(ROUTES.MENU, {}, search), [push, search]);
    const handleSelectReward = React.useCallback(
        (reward?: IAdjustmentLocalResource) => {
            if (reward) {
                handleSetReward(reward.id);
            } else {
                handleClearRewards();
            }
        },
        [handleClearRewards, handleSetReward]
    );
    const { isTippingEnabled, tippingToUse } = useBasketTipSettings(scenario);

    const handleErrorDialogClose = React.useCallback(() => {
        resetBasket(dispatch);
        orderApi.resetNonceByKey([EOrderNonceKey.CREATE_ORDER]);
        push(ROUTES.BASE);
    }, [dispatch, push]);

    const handleCloseQuantitySelect = React.useCallback(() => {
        setQuantitySelectProductIndex(-1);
    }, []);
    const handleOpenQuantitySelect = React.useCallback(
        (index: number) => {
            setQuantity(calculatedBasket?.items[index].quantity ?? 0);
            setQuantitySelectProductIndex(index);
        },
        [calculatedBasket]
    );
    const handleUpdateItemQuantity = React.useCallback(() => {
        updateBasketItemQuantity(quantitySelectProductIndex, quantity)(dispatch);
        updateTimePadding()(dispatch, getState);
        handleCloseQuantitySelect();
    }, [quantitySelectProductIndex, quantity, dispatch, getState, handleCloseQuantitySelect]);
    const handleClickItem = React.useCallback(
        (index: number) => {
            handleOpenQuantitySelect(index);
        },
        [handleOpenQuantitySelect]
    );
    const handleRewardChange = React.useCallback(
        (reward?: IAdjustmentLocalResource | undefined) => {
            handleSelectReward(reward);
        },
        [handleSelectReward]
    );

    const handleClickBack = React.useCallback(() => {
        push(ROUTES.MENU, {}, search);
    }, [push, search]);

    const handleOpenClearBasketDialog = React.useCallback(() => {
        setOpenClearBasketDialog(!openClearBasketDialog);
    }, [openClearBasketDialog]);

    const handleClearBasket = React.useCallback(() => {
        resetBasket(dispatch);
        push(ROUTES.MENU, {}, search);
    }, [dispatch, push, search]);

    const { giftCardPayment, totalValue } = useOrderGiftcardCalculator(
        totalAfterReward,
        tipAmount,
        giftCard,
        giftCardBalance
    );

    const {
        isPlacingOrder,
        isConfirmationOpen,
        handleToggleConfirmationDialog,
        submitOrder,
        submiteJudoDigitalOrder,
        submitDigitalOrder
    } = usePlaceOrder(
        totalValue,
        tipAmount,
        useTipAsDefault,
        selectedPaymentOption,
        scrollToPhoneNumberInput,
        validatePhoneNumber,
        onAddNewCardOpen,
        phoneNumber,
        selectedReward,
        giftCardPayment
    );

    React.useEffect(() => {
        clearSessionStorage();
        const params: ExistingSharedBasketData = {
            selectedReward,
            tipValue: tipAmount,
            giftCard,
            userSelectedTipValue
        };
        setExistingParams(params);
        setBasketSession(params, merchantId, locationId, basket?.checkId, basket?.deliveryLocation);
    }, [
        selectedReward,
        giftCard,
        merchantId,
        locationId,
        tipAmount,
        userSelectedTipValue,
        basket?.checkId,
        basket?.deliveryLocation
    ]);

    const handleConfirm = React.useCallback(() => {
        if (lastRequest) {
            handleToggleConfirmationDialog();
            lastRequest();
        }
    }, [handleToggleConfirmationDialog, lastRequest]);
    const handleValidatePhoneNumber = React.useCallback(() => {
        const phoneNumberValid = validatePhoneNumber();
        if (!phoneNumberValid) {
            scrollToPhoneNumberInput();
            throw new Error('The phone number is invalid');
        }
    }, [scrollToPhoneNumberInput, validatePhoneNumber]);

    const {
        isOutOfLimit,
        handleExceededLimitDialogClose,
        handleValidateTransactionLimit,
        showExceededLimitDialog,
        transactionLimitDescription
    } = useTransactionLimitValidation(totalValue);

    const readyToPay = React.useMemo(
        () => ((!phoneNumberError && !!phoneNumber) || !phoneCaptureEnabled) && !isOutOfLimit,
        [isOutOfLimit, phoneCaptureEnabled, phoneNumber, phoneNumberError]
    );

    const handleValidateOrder = React.useCallback(() => {
        handleValidatePhoneNumber();
        handleValidateTransactionLimit();
    }, [handleValidatePhoneNumber, handleValidateTransactionLimit]);

    return (
        <Box height="100%" width="100%" className="hidden-scroll">
            {loading && !calculatedBasket && (
                <Box height="100%" display="flex" flexDirection="column">
                    <ViewBillHeader title={t('CHECKOUT_TITLE')} onBack={handleOnBack} />
                    <Box flex={1}>
                        <Throbber text={t('GENERAL_LOADING')} />
                    </Box>
                </Box>
            )}
            {calculatedBasket && calculatedBasket.items.length > 0 && (
                <>
                    <BasketTimeslotPicker />
                    <InnerPageLayout>
                        <ViewBillHeader onBack={handleClickBack} title={t('BILL_CHECKOUT_TITLE')} />
                        <InnerPageLayoutContent>
                            <Throbber
                                isLoading={loading || isCardLoading}
                                text={t('GENERAL_LOADING')}
                                isOverlay
                            />
                            <Box marginBottom={2}>
                                <ViewBillHeaderInfo locationVisible={false} />
                            </Box>
                            <BasketItems
                                calculatedBasket={calculatedBasket}
                                onItemClick={handleClickItem}
                                onOpenClearBasketDialog={handleOpenClearBasketDialog}
                            />
                            {openClearBasketDialog && (
                                <ConfirmDialog
                                    open={openClearBasketDialog}
                                    onClose={handleOpenClearBasketDialog}
                                    onConfirm={handleClearBasket}
                                    title={t('CHECKOUT_EMPTY_BASKET_TITLE')}
                                    description={t('CHECKOUT_EMPTY_ALERT_TITLE')}
                                    confirmMessage={t('CHECKOUT_EMPTY_ALERT_YES')}
                                    cancelMessage={t('CHECKOUT_EMPTY_ALERT_NO')}
                                />
                            )}
                            {!!rewardsEnabled && (
                                <ViewBasketRewardList
                                    selected={selectedReward}
                                    onRewardSelected={handleRewardChange}
                                    availableRewards={availableRewards}
                                />
                            )}
                            {phoneCaptureEnabled && (
                                <ViewBillCheckoutPhoneNumber
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    error={phoneNumberError}
                                    ref={phoneNumberWrapperRef}
                                />
                            )}

                            <ViewBillCheckoutNoteList />
                            {!!settings && isTippingEnabled && !!tippingToUse && (
                                <ViewBillContentSection
                                    title={t('BILL_CHECKOUT_TIPS_TITLE')}
                                    subtitle={t('BILL_CHECKOUT_TIPS_BODY')}
                                    bottomSpacing={2}
                                >
                                    <ViewBillCheckoutTip
                                        useTipAsDefault={useTipAsDefault}
                                        onUseTipAsDefaultChange={onUseTipAsDefaultChange}
                                        total={totalAfterReward}
                                        tipping={tippingToUse}
                                        onTipUpdate={onSubmitTip}
                                        currencySymbol={settings.region.currencySymbol}
                                        userDefaultSelectedValue={userSelectedTipValue}
                                    />
                                </ViewBillContentSection>
                            )}
                            <ViewBillContentSection
                                title={t('BILL_CHECKOUT_PAYMENT_METHOD_TITLE')}
                                bottomSpacing={2}
                            >
                                <ViewBillPaymentMethod
                                    cards={userCards}
                                    selectedPaymentMethod={selectedPaymentOption}
                                    onPaymentMethodChange={onCardChange}
                                    onAddNewCardClick={onAddNewCardOpen}
                                />
                            </ViewBillContentSection>
                            {settings?.giftCardPaymentEnabled && (
                                <ViewBillContentSection bottomSpacing={2}>
                                    <BasketGiftCard
                                        balance={giftCardBalance}
                                        error={giftCardError}
                                        loading={giftCardLoading}
                                        onGiftCardApply={submitGiftCard}
                                        giftCardValue={giftCard}
                                        reset={resetGiftCard}
                                        resetErrors={resetGiftCardErrors}
                                    />
                                </ViewBillContentSection>
                            )}

                            <ViewBasketPaymentInfo
                                tip={tipAmount}
                                scenario={scenario}
                                calculatedBasket={calculatedBasket}
                                total={totalValue}
                                giftCardPayment={giftCardPayment}
                                selectedAward={selectedReward}
                            />

                            <ViewBasketPayButton
                                totalToPay={totalValue}
                                selectedPaymentOption={selectedPaymentOption}
                                readyToPay={readyToPay}
                                onIntegratedWalletsPay={submiteJudoDigitalOrder}
                                onWalletsPay={submitDigitalOrder}
                                onCardPay={submitOrder}
                                loading={isPlacingOrder}
                                validate={handleValidateOrder}
                            />
                        </InnerPageLayoutContent>
                    </InnerPageLayout>
                    <CardPayment
                        open={addNewCardOpen}
                        handleCreateOrder={onCardAdded}
                        onClose={onAddNewCardClose}
                        isPlacingOrder={isPlacingOrder}
                    />
                    <GuestCheckout onClickBack={handleClickBack} />
                    <BottomDialog open={quantitySelectProductIndex >= 0} onClose={handleCloseQuantitySelect}>
                        <Box
                            width="100%"
                            height="100%"
                            display="flex"
                            alignItems="center"
                            flexDirection="column"
                        >
                            <QuantitySelector min={0} max={999} quantity={quantity} onChange={setQuantity} />

                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleUpdateItemQuantity}
                            >
                                {quantity === 0
                                    ? t('BASKET_REMOVE_ITEM')
                                    : t('PREORDER_UPDATE_BASKET_QUANTITY')}
                            </Button>
                        </Box>
                    </BottomDialog>
                    <ConfirmDialog
                        open={isConfirmationOpen}
                        disableBackdropClick
                        disableEscapeKeyDown
                        onClose={handleErrorDialogClose}
                        confirmMessage={t('RETRY')}
                        onConfirm={handleConfirm}
                        description={t('DIALOG_ORDER_STATUS_UNKNOWN')}
                    />
                    <ConfirmDialog
                        open={showExceededLimitDialog}
                        disableBackdropClick
                        disableEscapeKeyDown
                        isCancellable={false}
                        onClose={handleExceededLimitDialogClose}
                        confirmMessage={t('OK')}
                        onConfirm={handleExceededLimitDialogClose}
                        description={transactionLimitDescription}
                        title={t('DIALOG_OOPS')}
                        align="center"
                    />
                </>
            )}
            {!loading && !calculatedBasket && basket.items.length !== 0 && (
                <Box height="100%" display="flex" flexDirection="column">
                    <ViewBillHeader title={t('CHECKOUT_TITLE')} onBack={handleOnBack} />
                    <EmptyState
                        headerText={t('GENERAL_GENERIC_ERROR')}
                        paragraphText={t('BASKET_ERROR_LOADING', {
                            errorMessage: 'An unknown error has occurred'
                        })}
                    >
                        <ErrorOutline className={classes.basketError} />
                    </EmptyState>
                </Box>
            )}
            {!loading &&
                ((!calculatedBasket && basket.items.length === 0) ||
                    (!!calculatedBasket && calculatedBasket.items.length === 0)) && (
                    <Box
                        height="100%"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <ViewBillHeader title={t('CHECKOUT_TITLE')} onBack={handleOnBack} />
                        <EmptyState
                            headerText={t('DIALOG_BASKET_EMPTY_TITLE')}
                            paragraphText={t('DIALOG_BASKET_EMPTY_BODY')}
                        >
                            <ShoppingCart className={classes.emptyBasket} />
                        </EmptyState>
                    </Box>
                )}
        </Box>
    );
};
