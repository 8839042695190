import { Box } from '@material-ui/core';
import React from 'react';
import { AddToWalletButton } from './AddToWalletButton';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { UserQrCode } from './UserQrCode';

export const AwardUserSection: React.FC = () => {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    return (
        <Box display="flex" flexDirection="column" width="100%" alignItems="center">
            {settings?.offlineCheckinEnabled && <UserQrCode />}
            <AddToWalletButton />
        </Box>
    );
};
